@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

/* --- HEADER ---  */
header {
  padding: var(--sm-spacing) var(--spacing);
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px;
  z-index: 1000;
}

.logo {
  height: 30px;
}

.links {
  display: none; /* Hidden by default for mobile */
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1001;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: var(--text-color);
  margin: 4px;
  transition: all 0.3s ease;
}

/* Opened hamburger cross effect */
.hamburger.open div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open div:nth-child(2) {
  opacity: 0;
}

.hamburger.open div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Mobile Navigation */
.mobile-nav {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-color);
    z-index: 1000;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }
  
  .mobile-nav.open {
    display: flex;
  }
  
  .nav-links.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .nav-links.mobile a {
    color: var(--text-color);
    font-size: 18px;
    margin-bottom: 15px;
    text-decoration: none;
    width: 100%;
    text-align: center;
    padding: 15px 0;
  }
  
  .nav-links.mobile a:hover {
    color: var(--hl-color);
  }
  

@media (max-width: 750px) {
  .hamburger {
    display: flex;
  }

  .links {
    display: none; /* Keep links hidden on mobile */
  }
}
/* --- DESKTOP NAVIGATION --- */
@media (min-width: 750px) {
    .links {
      display: flex;
      background-color: var(--bg-color);
      justify-content: flex-end;
      position: relative;
      width: auto;
      padding: 0;
    }
  
    .links a {
      text-decoration: none;
      color: var(--text-color);
      font-size: 15px;
      padding: 10px 0;
      position: relative;
    }
  
    .links a:not(:last-child) {
      margin-right: 20px;
    }
  
    .links a:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: var(--text-color);
      transition: width 0.3s ease-in-out;
    }
  
    .links a:hover:before {
      width: 100%;
    }
  
    .links a.active:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--text-color);
      transition: width 0.3s ease-in-out;
    }
  
    .links.open {
      display: flex;
    }
  
    .dropdown-toggle {
      display: none;
    }
  
    .close-menu {
      display: none;
    }
  }
  