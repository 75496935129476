/* --- LANDING STYLES --- */
.landing {
  height: calc(100% - 93px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px; 
}

.landing .name,
.landing .description {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  text-align: center;
  padding-top: 0px;
  z-index: 2;
}

.landing .description {
  top: 100px;
  font-size: 22px;
}

.landingImage {
  right: 0px;
}

.socialIconsLanding {
  position: absolute;
  left: 20px;
  bottom: 20px;
}


/* --- MEDIA QUERIES --- */

@media (max-width: 380px) {
  .landing .description {
    display: none;
  }
}

@media (max-width: 750px) {
  .landing {
    padding: 0 10px; 
  }

  .landing .name {
    font-size: 48px;
    padding-top: 30px;
  }

  .landing .description {
    font-size: 18px;
  }

  .socialIconsLanding {
    position: absolute;
    left: auto;
    right: 10px;
    bottom: 20px;
    flex-direction: column;
  }
}

@media (min-width: 750px) {
  
  .landing .name {
    font-size: 70px;
  }

  .landing .description {
    font-size: 32px;
  }

  .landingImage {
    bottom: 0;
    right: 80px;
  }

  .landing .socialIcons {
    flex-direction: column;
  }
}

/* --- GENERAL LANDING ANIMATION --- */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing {
  animation: fadeIn 0.5s ease-out forwards;
}

