/* --- MOBILE STYLES --- */

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Added justify-content: center */
  animation: fadeIn 0.5s ease-out forwards;

}

h2{
  padding-top: 20px;
}

.personalImage {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  
  
}

.personalImage img {
  height: 250px;
  z-index: 50;
  padding: 6px;
  border: 1px solid grey;
}

.contentContainer {
  padding: 1px;
  background: rgba(255, 255, 255, 0); /* Transparent background */
  border-radius: 12px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
  padding: 10px; 
}
.contentContainer p {
  color: var(--secondary-text-color);
  font-size: 16px;
  margin-bottom: 15px;
  
}
@media (max-width: 750px) {
  .contentContainer p {
    text-align: center;
  }
}

.contentContainer h4 {
  color: var(--hl-color);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.contentContainer h5 {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 15px;
}

@media (max-width: 750px) {
  .contentContainer h5 {
    text-align: center;
    padding-bottom: 6px;
    /*border-bottom: 1px solid var(--grey);*/
  }
  
}

.contentDescription {
  color: var(--secondary-text-color);
}

.infoContainer {
  border-top: 1px solid var(--grey);
  margin-top: var(--sm-spacing);
  padding-top: var(--sm-spacing);
}




@media (max-width: 750px) {
  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%; /* Make sure the container spans the full width */
    border-bottom: 1px solid var(--grey);
  }


  /* Make sure this applies to all child elements that need to be centered */
  .infoContainer .info, .infoContainer a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%; /* Make sure the container spans the full width */
  }
}



.infoContainer span {
  color: var(--text-color);
}

.infoContainer p {
  color: var(--secondary-text-color);
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.infoContainer a {
  color: var(--secondary-text-color);
  text-decoration: none;
}

.infoContainer a:hover {
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--sm-spacing);
}

.downloadCV {
  margin-top: var(--sm-spacing);
  margin-bottom: var(--sm-spacing);
}
.desktopSocialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--sm-spacing);
}

@media (min-width: 750px) {
  .about .desktopSocialIcons .icon {
    display: inline;
  }
  .mobileSocialIcons{
    display: none;
  }

  .about .desktopSocialIcons::before {
    background-color: var(--grey);
    content: "";
    height: 2px;
    width: var(--sm-spacing);
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 10px;
  }
}

@media (max-width: 750px) {
  
  .about {
    margin-top: 20px;
  }
  .mobileSocialIcons{
    display: block;
    padding-bottom: 100px;
  }
  .desktopSocialIcons{
    display: none;
  }
}



@media (min-width: 992px) {
  .personalImage img {
    height: 330px;
    z-index: 0;
    border-radius: 0;
    padding: 6px;
    border: 1px solid grey;
  }

  .personalImage {
    height: 330px;
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



/* --- ROAD MAP --- */
.roadmap-container {
  background-color: var(--bg-color);
  padding: 20px;
}

.roadmap-title {
  color: var(--text-color);
  font-size: 24px;
  margin-bottom: 20px;
}

.roadmap-list {
  list-style-type: none;
  padding: 0;
}

.roadmap-step {
  display: flex;
  align-items: flex-start;
}

.roadmap-step-number {
  background-color: var(--hl-color);
  color: var(--text-color);
  font-size: 16px;
  width: 105px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.roadmap-step-details {
  flex: 1;
  border: none;
  background: rgba(255, 255, 255, 0); /* Transparent background */
  border-radius: 12px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
}

.roadmap-step-title {
  color: var(--text-color);
  font-size: 18px;
  margin-bottom: 10px;
}

.roadmap-step-description {
  color: var(--text-color);
  font-size: 14px;
}