/* --- MOBILE STYLES --- */

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Added justify-content: center */
  margin-top: var(--spacing);
}

.contentTitle {
  color: var(--text-color);
  padding-bottom: 10px;
  text-align: center; /* Added text-align: center */
}

.contactInfo {
  font-size: 12px;
  color: var(--secondary-text-color);
  margin-bottom: var(--sm-spacing);
  margin-left: auto; /* Updated margin-left to auto */
  margin-right: auto; /* Added margin-right: auto */
  text-align: center; /* Added text-align: center */

}

.contentDescription {
  font-size: 15px;
  text-align: center; /* Added text-align: center */
}

.info {
  font-size: 15px;
  justify-content: center; /* Added justify-content: center */

}

.infoDescription {
  margin-bottom: var(--sm-spacing);
  justify-content: center; /* Added justify-content: center */
}

.personalContactInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  display: flex;
  flex-direction: column;
  list-style: none;

}
ul.listInfo {
  display: flex;
  flex-direction: column;
  list-style: none;
}

ul.listInfo li {
  justify-content: center;
  display: flex;
  align-items: center; /* Align items at the beginning */
  margin-bottom: 10px; /* Optional margin between list items */
}


.mediaWrap {
  border-left: 2px solid var(--grey);
  padding-left: var(--sm-spacing);
  padding-top: 12px;
  padding-bottom: 12px;

}

.formGroup {
  padding-right: 15px;

}

.formControl {
  font-size: 15px;
  width: 100%;
  padding: 10px 10px;
  border: none;
  background-color: #161616;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--grey);
  border-radius: 1px;
  outline: transparent;
  color: var(--grey);
}

.formControl:focus {
  border-bottom: 1px solid var(--hl-color);
}

textarea {
  resize: none;
  height: auto;
  font-family: "Poppins", sans-serif;
}

.icon {
  color: var(--hl-color);
  font-size: 28px;
  margin-right: 10px;
}

.infoIcon {
  width: var(--spacing);
  display: flex;
  align-items: center;
}

.infoType {
  color: var(--text-color);
  font-size: 15px;
  justify-content: center;
}

.infoValue a {
  color: var(--hl-color);
}

.infoValue a:hover {
  font-weight: bold;
}

.contactForm {
  margin-bottom: var(--sm-spacing);
}

.contact .socialIcons {
  display: none;
}

.mediaWrap a:hover {
  color: #03b979;
}

@media (min-width: 400px) {
  .contactInfo {
    font-size: 15px;
  }
}

@media (min-width: 750px) {
  .contact .socialIcons {
    display: inline;
    padding-bottom: var(--sm-spacing);
  }

  .contact .socialIcons::before,
  .contact .socialIcons::after {
    background-color: var(--grey);
    content: "";
    height: 2px;
    width: var(--spacing);
    display: inline-block;
    margin-bottom: 7px;
    margin-right: 10px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact {
  animation: fadeIn 0.5s forwards;
}