@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

/* --- VARIABLES --- */
:root {
  --bg-color: rgba(12,13,14,255);
  --hl-color: rgba(255, 255, 255, 0.459);
  --text-color: #f3f3f3;
  --secondary-text-color: #9f9f9f;
  --grey: #9f9f9f;
  --spacing: 50px;
  --sm-spacing: 30px;
}

/* --- DYNAMISCHE VIEWPORT-HÖHE --- */
:root {
  --vh: 100vh; /* Fallback für den Fall, dass JavaScript nicht ausgeführt wird */
}

html, body, #root {
  height: calc(var(--vh, 1vh) * 100); /* Verwende die dynamische Höhe */
}




/* For Webkit based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0.5em;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

body {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* For Internet Explorer and Edge */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


/* --- ANIMATIONS --- */
@keyframes slideDown {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(1%);
    opacity: 1;
  }
}

/* --- RESETS --- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--bg-color);
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%; /* Sichert, dass der root Container die volle Höhe einnimmt */
  background-color: var(--bg-color);
}

/* --- GLOBAL STYLES --- */
::selection {
  background-color: var(--hl-color);
  color: var(--text-color);
}

.pageTitle {
  color: var(--text-color);
  font-size: 36px;
  border-bottom: 3px solid white;
  margin-bottom: var(--spacing);
}

.pageDescription {
  color: var(--secondary-text-color);
  font-size: 14px;
}

.socialIcons .icon {
  color: var(--text-color);
}

.socialIcons .icon:hover {
  scale: 1.2;
  color: var(--hl-color);
}

.btn {
  color: #f9f9f9;
  background-color: var(--hl-color);
  border: none;
  font-size: 14px;
  padding: 12px var(--sm-spacing);
  margin-top: 10px;
  cursor: pointer;
}

.btn:hover {
  background-color: #03b979;
}

/* --- MEDIA QUERIES --- */
@media (min-width: 750px) {
  .dropdown-toggle {
    display: none;
  }

  .closed {
    background-color: transparent;
  }
}
