/* Global Styles */
html, body {
    height: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif;
    color: #ffffff;
    background-color: #181818;
}

/* Chatbot Container */
.leon-gpt-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 80%;
    width: 100%;
    padding-top: 2vh;
    animation: fadeIn 0.5s ease-out forwards;
}
.chat-wrapper {
    width: 90%;
    max-width: 600px;
    border-radius: 12px; /* Rounded corners for the whole container */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Shadow around both elements */
    display: flex;
    flex-direction: column; /* Stack the chat window and input bar */
}

/* Chat Window */
.chat-window-leon-gpt {
    width: 100%;
    max-width: 600px;
    height: 40vh;
    overflow-y: auto;
    padding: 1em;
    background-color: #00000000; /* Dark background for contrast */
    border-radius: 12px 12px 0 0; /* Rounded corners at the top only */
    box-shadow: none; /* Remove shadow for connection with input bar */
    z-index: 3;
}
/* Message Styling */
.message {
    padding: 1em;
    border-radius: 12px; /* Modern rounded corners */
    max-width: 60%;
    display: inline-block;
    word-wrap: break-word;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Soft shadow */
}

.message-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
}

.message-container.user {
    justify-content: flex-end;
}

/* User Message Icon */
.message-icon-user {
    width: 2.5em;
    height: 2.5em;
    margin-bottom: 1.5em;
    margin-left: 0.6em;
    border-radius: 50%; /* Keep circular */
    order: 2;
}

/* User Message */
.message.user {
    background-color: rgba(22,23,24,255); /* Softer dark color */
    text-align: right;
    color: white;
    padding: 1em;
    border-radius: 15px;
    border-top-right-radius: 0;
    margin-left: 0.6em;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Modern shadow */
}

.message.user::before {
    content: '';
    position: absolute;
    top: 0;
    right: -0.6em;
    width: 0;
    height: 0;
    border-top: 10px solid rgba(22,23,24,255);
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

/* Bot Message Icon */
.message-icon-bot {
    width: 2.5em;
    height: 2.5em;
    margin-bottom: 1.5em;
    margin-right: 0.6em;
    border-radius: 50%;
}

/* Bot Message */
.message.bot {
    background-color: rgba(22,23,24,255);
    text-align: left;
    color: white;
    padding: 1em;
    border-radius: 15px;
    border-top-left-radius: 0;
    margin-right: 0.6em;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.message.bot::before {
    content: '';
    position: absolute;
    top: 0;
    left: -0.6em;
    width: 0;
    height: 0;
    border-top: 10px solid rgba(22,23,24,255);
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

/* Description Styling */
.gpt-description {
    margin-bottom: 2vh;
    font-size: 1rem;
    text-align: center;
    color: #BBBBBB; /* Light gray text for modern design */
    line-height: 1.5;
    max-width: 90%;
    max-width: 50em;
}

@media screen and (max-width: 600px) {
    .gpt-description {
        max-width: 80%;
    }
}

/* Desktop Input Bar */
.input-bar-desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    padding-top: 0; /* No padding at the top to remove the gap */
    background-color: rgba(44, 44, 44, 0);
    border-radius: 0 0 12px 12px; /* Rounded corners at the bottom only */
    box-sizing: border-box;
    box-shadow: none; /* Remove shadow for a seamless connection */
    z-index: 3;
    border-top: rgba(22,23,24,255) 1px solid;
}

/* Chat Input Field */
.chatbot-input {
    flex-grow: 1;
    padding: 1em;
    font-size: medium;
    background-color: rgba(44, 44, 44, 0);
    border: none;
    margin-right: 1em;
    color: white;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for input */
}

.chatbot-input:focus {
    outline: none;
}

/* Send Button */
.chatbot-send-btn {
    padding: 1em 1.5em;
    background-color: rgba(22,23,24,255);
    color: white;
    border: none;
    cursor: pointer;
    
    font-size: medium;
    box-sizing: border-box;
    border-radius: 8px 0px 8px 8px;
    transition: background-color 0.3s ease, transform 0.2s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Modern button shadow */
}

.chatbot-send-btn:hover {
    background-color: #525252;
    transform: translateY(-2px); /* Slight lift effect on hover */
}

/* Mobile Input Bar */
.input-bar-mobile {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    background-color: rgba(44, 44, 44, 0);
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3); /* Soft shadow for mobile bar */
    z-index: 10;
}

.chatbot-input-mobile {
    flex-grow: 1;
    padding: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    
    font-size: medium;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.chatbot-input-mobile:focus {
    outline: none;
}

.chatbot-send-btn-mobile {
    padding: 0.8rem 1.5rem;
    background-color: #3A3A3A;
    color: white;
    border: none;
    
    font-size: medium;
    margin-left: 0.5rem;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.2s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.chatbot-send-btn-mobile:hover {
    background-color: #525252;
    transform: translateY(-2px);
}

/* Mobile Media Query */
@media (max-width: 600px) {
    .chat-window {
        box-shadow: none;
        margin-bottom: calc(20vh + 20px);
    }

    .chat-window-leon-gpt {
        border: none;
    }

    .gpt-description {
        font-size: small;
    }

    .message-container {
        margin-bottom: 0.4em;
    }

    .input-bar-desktop {
        display: none;
    }

    .leon-gpt-container {
        height: 100%;
    }

    .input-bar-mobile {
        display: flex;
    }
}

/* Loading Circle */
.loading-circle {
    width: 3em;
    height: 3em;
    border: 0.4em solid rgba(0, 0, 0, 0.1);
    border-top: 0.4em solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 2vh auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
