.roadmap-container {
    display: flex;
    flex-direction: column; /* Standardmäßig vertikal */
    align-items: center;
}
body{
    color: white;
}

@media (min-width: 768px) { /* Für Bildschirme breiter als 768px */
    .roadmap-container {
        flex-direction: row-reverse; /* Horizontales Layout auf Desktop, aktuellste rechts */
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .roadmap-item { /* Entfernt den Pfeil beim visuell ersten (aktuellsten) Element */
        position: relative; /* Ermöglicht die Positionierung des Pfeils */
    }
    .roadmap-item:first-child::before {
        display: none; /* Entfernt den Pfeil beim visuell ersten (aktuellsten) Element */
    }
    
    .roadmap-item::before { /* Stil für den Pfeil */
        content: '';
        position: absolute;
        top: 50%;
        right: -30px; /* Pfeilabstand zum nächsten Element, da row-reverse verwendet wird */
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid white; /* Pfeilrichtung nach rechts, da row-reverse */
        transform: translateY(-50%);
    }
    
}

@media (max-width: 768px) { 
    .roadmap-container {
        flex-direction: column; /* Vertikales Layout auf Mobilgeräten */
        align-items: center;
    }
    .roadmap-item {
        position: relative; /* Ermöglicht die Positionierung des Pfeils */
        width: 90%; /* Anpassung der Breite für Mobilgeräte */
        margin: 20px 0; /* Anpassung des Abstands für eine klare Trennung */
    }

    


    .roadmap-item:not(:first-child)::before { /* Stil für den Pfeil, nur für Elemente nach dem ersten */
        content: '';
        position: absolute;
        top: -20px; /* Positioniert den Pfeil über dem Element */
        left: 50%; /* Zentriert den Pfeil horizontal */
        transform: translateX(-50%) rotate(90deg); /* Dreht den Pfeil, so dass er nach oben zeigt */
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white; /* Richtung des Pfeils nach oben */
        transform: translateY(-50%);
    }
}


.roadmap-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: 300px;
    height: 200px;
}

.roadmap-step-details {
    padding: 10px;
    border: 1px solid #ccc;
    color: var(--secondary-text-color); /* Stellen Sie sicher, dass diese Variable definiert ist oder ersetzen Sie sie durch eine spezifische Farbe */
    font-size: auto;
}

.roadmap-step-details h3 {
    margin: 0;
    color: white; /* Stellen Sie sicher, dass der Hintergrund der .roadmap-step-details dies unterstützt */
}


.ongoing {
    background-color: var(--secondary-text-color); /* Helle Warnfarbe für die Markierung */
    color: white; /* Weiße Schriftfarbe für Kontrast */
    font-weight: bold; /* Fettgedruckte Schrift */
    padding: 2px 6px; /* Ein wenig Innenabstand */
    font-size: 0.8em; /* Etwas kleinere Schriftgröße */
    margin-left: 8px; /* Abstand zum Jahr */
    vertical-align: middle; /* Ausrichtung mit dem Jahrestext */
    border-radius: 6px;
}

#comp_link {
    color: var(--secondary-text-color);
    text-decoration: none;
    font-weight: bold;
}