/* Chatbot widget container */
.leon-chat-widget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
  margin-top: 20px;
  border-radius: 15px; /* Rounded corners */
}

.chatbot-widget-title {
  align-self: start;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.chatbot-window {
  width: 100%;
  max-width: 600px;
  height: 60%;
  overflow-y: auto;
  padding: 15px;
  margin-bottom: 0;
  background: rgba(255, 255, 255, 0); /* Transparent background */
  border-radius: 15px;
}

.chatbot-message {
  padding: 10px;
  border-radius: 12px; /* Smooth rounded corners */
  max-width: 70%;
  display: inline-block;
  word-wrap: break-word;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Message shadow */
}

.chatbot-message-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.chatbot-message-container.user {
  justify-content: flex-end;
}

.chatbot-message-icon-user {
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
  margin-left: 10px;
  border-radius: 50%; /* Circular icon */
  order: 2;
}

.chatbot-message.chatbot-message-user {
  background-color: #3A3A3A;
  text-align: right;
  color: white;
  max-width: 70%;
  word-wrap: break-word;
  display: inline-block;
  position: relative;
  padding: 10px 15px;
  border-radius: 15px;
  border-top-right-radius: 0;
  margin-left: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Shadow for user message */
}

.chatbot-message.chatbot-message-user::before {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #3A3A3A;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.chatbot-message-icon-bot {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 50%;
}

.chatbot-message.chatbot-message-bot {
  background-color: #444;
  text-align: left;
  color: white;
  max-width: 70%;
  word-wrap: break-word;
  display: inline-block;
  position: relative;
  padding: 10px 15px;
  border-radius: 15px;
  border-top-left-radius: 0;
  margin-right: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Shadow for bot message */
}

.chatbot-message.chatbot-message-bot::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #444;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.chatbot-input-bar {
  display: flex;
  width: 100%;
  max-width: 600px;
  background: rgba(77, 77, 77, 0.082);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.chatbot-input-field {
  flex-grow: 1;
  padding: 15px 20px;
  font-size: 18px;
  border: none;
  outline: none;
  background: transparent;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.chatbot-input-field::placeholder {
  color: #cccccc;
}

.chatbot-input-field:focus {
  background: rgba(0, 0, 0, 0.2);
  outline: none;
}

.chatbot-send-button,
.chatbot-clear-button,
.chatbot-chat-button,
.chatbot-more-button {
  background-color: rgba(71, 71, 71, 0.08);
  color: #ffffff;
  border: none;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Button shadow */
}
.chatbot-chat-button:hover,
.chatbot-send-button:hover,
.chatbot-clear-button:hover,
.chatbot-more-button:hover {
  background-color: rgba(66, 66, 66, 0.8);
  transform: translateY(-2px); /* Lift on hover */
}

.chatbot-chat-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 20px auto;
}

.chatbot-response-area {
  width: 100%;
  max-width: 600px;
  background: rgba(78, 78, 78, 0.123);
  padding: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px; /* Rounded corners */
}

.chatbot-more-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin-top: 20px;
  text-align: center;
  background-color: rgba(92, 91, 91, 0.2);
  border-color: rgba(46, 46, 46, 0.07);
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.chatbot-more-button:hover {
  background-color: rgba(66, 66, 66, 0.8);
  transform: translateY(-2px);
}

/* --- LOADING CIRCLE --- */
.loading-indicator {
  width: 40px;
  height: 40px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* --- MOBILE OPTIMIZATIONS --- */
@media (max-width: 600px) {
  .leon-chat-widget-container {
      margin-top: 80px;
      padding: 20px; /* Adjust padding for smaller screens */
  }

  .chatbot-input-bar {
      flex-direction: row;
      width: 100%;
      align-items: center;
  }

  .chatbot-input-field {
      flex-grow: 1;
      width: auto;
  }


  .chatbot-response-area {
      max-width: 100%;
      padding: 15px;
  }

  .chatbot-more-button {
      width: 100%;
  }
}
