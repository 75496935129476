
/* --- PORTFOLIO --- */
.portfolio {
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Added justify-content: center */
  padding: 20px;
  background: rgba(255, 255, 255, 0); /* Transparent background */
  border-radius: 12px;
  backdrop-filter: blur(10px);
}

.pageTitle {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.projects-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  position: relative;
  height: auto;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 800px;
}

.project-card {
  background: rgba(255, 255, 255, 0); /* Transparent background */
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 12px; /* Rounded corners */
  overflow: hidden;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
}

.project-card h4 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.project-card p {
  font-size: 1.1rem;
  color: #dddddd;
  margin-bottom: 15px;
  transition: font-size 0.3s ease;
  line-height: 1.4;
}

.project-card .description {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-card .last-updated {
  font-size: 0.9rem;
  color: #aaaaaa;
  margin-bottom: 25px;
}

.project-link {
  display: inline-block;
  padding: 10px 25px;
  background-color: rgba(22,23,24,255);
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border-radius: 8px; /* Rounded corners */
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
}

.project-link:hover {
  background-color: #6b6b6b38;
}

.carousel-btn,
.mobile-nav-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin: 10px;
  transition: transform 0.2s;
}

.carousel-btn img,
.mobile-nav-btn img {
  width: 40px;
  height: 40px;
}

.carousel-btn:hover,
.mobile-nav-btn:hover {
  transform: scale(1.15);
}

.mobile-navigation {
  display: none; /* Hidden on larger screens */
}

.pagination-dots {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.dot {
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  display: inline-block;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dot.active {
  background-color: #dddddd;
}

/* --- RESPONSIVE STYLES --- */
@media (max-width: 768px) {
  .projects-carousel {
    margin-top: 20px;
  }

  .project-card {
    max-width: 100%;
    height: 350px;
    padding: 20px;
  }

  .project-card p {
    font-size: calc(1.1rem - 0.2vw);
  }

  .carousel-btn {
    display: none;
  }

  .pagination-dots {
    display: none;
  }

  .mobile-navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .project-card {
    padding: 15px;
    max-width: 100%;
    height: 420px;
  }

  .project-card p {
    font-size: calc(0.9rem - 0.4vw);
  }

  .carousel-btn img,
  .mobile-nav-btn img {
    width: 30px;
    height: 30px;
  }

  .mobile-navigation {
    margin-top: 10px;
  }
}
