/* Global Styles */
html, body {
    height: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif;
    color: #ffffff;
}

/* Game Styling */
.game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s ease-out forwards;
    padding: 10px;
}

.game-wrapper {
    display: flex;
    flex-direction: column; /* Stack the game container and leaderboard vertically on small screens */
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.game-container {
    position: relative;
    width: 35vh; /* Maintain width to be based on viewport height */
    height: 60vh;
    background-color: var(--bg-color);
    border-radius: 12px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    box-shadow: none;
    border-radius: 12px; /* Smooth rounded corners */
}
.mobile-game-container {
    position: relative;
    width: 90vw; /* Maintain width to be based on viewport height */
    height: 60vh;
    background-color: var(--bg-color);
    bottom: 0;
    border-radius: 12px;
    overflow: hidden;

    background-color: var(--bg-color);
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
    border-radius: 12px; /* Smooth rounded corners */
}

.player {
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url('../assets/images/bug.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
}

.platform {
    position: absolute;
    width: 60px;
    height: 10px;
    background-color: white;
    border-radius: 5px; /* Slightly rounded corners */
}

.start-screen, .game-over {
    position: relative;
    top: 10%;
    text-align: center;
    margin: 10px;
    color: white;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: var(--bg-color);
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
    border-radius: 15px; /* Smooth, rounded corners */
}

.start-screen h1, .game-over h1 {
    margin-bottom: 20px;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-top: 10px;
    color: #ffffff;
}

.start-screen button, .game-over button {
    padding: 15px 25px;
    font-size: 16px;
    color: white;
    background-color: #3a3a3a;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
    border-radius: 8px; /* Smooth, rounded corners */
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
    border: none;
}

.start-screen button:hover, .game-over button:hover {
    background-color: #525252; /* Slightly darker color on hover */
    transform: translateY(-2px); /* Subtle lift effect */
}

.start-screen input[type="text"] {
    width: 80%;
    max-width: 300px;
    padding: 12px;
    margin-bottom: 20px;
    font-size: 16px;
    background-color: var(--bg-color);
    color: white;
    text-align: center;
    outline: none;
    border-radius: 8px; /* Smooth, rounded corners */
    border: none;
}

.start-screen input[type="text"]::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.highscore {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    color: white;
    background-color: var(--bg-color);
    font-family: 'Arial', sans-serif;
    backdrop-filter: blur(10px);
    padding: 10px 15px;
    border-radius: 10px; /* Rounded corners for highscore */
}

.leaderboard {
    width: 35vh;
    height: 60vh;
    background-color: var(--bg-color);
    padding: 15px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 15px; /* Rounded corners */
    overflow-y: auto;
}

.leaderboard h2 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.leaderboard table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: auto;
}

.leaderboard th, .leaderboard td {
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid white;
    color: var(--text-color);
}

.leaderboard tbody {
    display: block;
    max-height: 450px;
    overflow-y: auto;
    width: 100%;
}

.leaderboard tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.leaderboard tbody tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
}

.back-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: rgba(22,23,24,255);
    cursor: pointer;
    position: fixed;
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0.3s ease, transform 0.2s;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border: none;
}

.back-button:hover {
    background-color: #525252;
    transform: translateY(-2px);
}

.game-description {
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    color: gray;
    line-height: 120%;
    max-width: 300px;
}

/* Desktop Styles */
@media (min-width: 769px) {
    .game-wrapper {
        flex-direction: row; /* Place game container and leaderboard side by side */
        gap: 20px;
        justify-content: center;
        align-items: flex-start;
    }
}

/* Mobile Styles */
@media (max-width: 768px) {
    .game-wrapper {
        flex-direction: column; /* Stack game and leaderboard vertically */
        gap: 20px;
    }

    .game-container {
        width: 90vw; /* Full width on small screens */
        height: 50vh;
    }

    .leaderboard {
        position: relative;
        width: 90vw; /* Gleiche Breite wie der mobile-game-container */
        height: 60vh; /* Gleiche Höhe wie der mobile-game-container */
        background-color: var(--bg-color);
        padding: 15px;
        backdrop-filter: blur(10px);
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow */
        color: var(--text-color);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 12px; /* Gleiche abgerundete Ecken wie der mobile-game-container */
        overflow-y: auto;
    }
    

    .start-screen, .game-over {
        width: 90vw;
        padding: 10px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        border-radius: 15px;
    }

    .scrollable-leaderboard {
        max-height: 40vh;
    }
}

.start-screen button, .game-over button {
    display: block; /* Makes the buttons take up the full width */
    width: 100%; /* Ensures buttons are full width */
    max-width: 300px; /* Limits button width to a reasonable size */
    margin: 10px auto; /* Centers the buttons and adds space between them */
    font-size: 16px;
    padding: 15px 25px;
    color: white;
    background-color: rgba(22,23,24,255);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Soft shadow for modern look */
    border: none;
}

.start-screen button:hover, .game-over button:hover {
    background-color: #525252; /* Darker color on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
}

/* Very small devices */
@media (max-width: 480px) {
    .game-container {
        width: 80vw;
        height: 40vh;
    }

    .leaderboard {
        width: 80vw;
        max-height: 30vh;
    }

    .start-screen button, .game-over button {
        font-size: 14px;
    }

    .scrollable-leaderboard {
        max-height: 30vh;
    }
}